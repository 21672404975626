import { IParametersFetch } from "../interfaces/fetch/parametersFetch";
import { ResponseResult } from "../interfaces/fetch/responseResult";
import { TypeReturn } from "../interfaces/fetch/typeReturn";

/**
  * Получение данных из апи
  * @param parameters - параметры для FETCH
  * @returns
  */
const getResource = async (parameters: IParametersFetch<string>): Promise<ResponseResult> => {
  const requestOptions: RequestInit = {
    method: parameters.methodRequest,
    headers: parameters.headers,
    body: parameters.body,
  };
  const result = await fetch(parameters.url, requestOptions);

  if (!result.ok) {
    console.error(result.status);
    //window.location.replace("/404");
  }

  if (result.status === 204) {
    //return null;
    return {
      status: result.status,
      result: null,
    };
  }

  /*if (result.status === 400) {
    window.location.replace("/404");
  }*/

  let totalResult: unknown;

  switch (parameters.typeReturn) {
    case TypeReturn.json:
      totalResult = await result.json();

      break;
    case TypeReturn.string:
    default:
      totalResult = await result.text();
  }

  const responseResult: ResponseResult = {
    status: result.status,
    result: totalResult
  };

  return responseResult;
};


export default getResource;