import { useRouter } from 'next/router';
import Image from 'next/image';

export const Header = (): JSX.Element => {
  const router = useRouter();

  const currentUrl = router.pathname;

  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-md fixed-top d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom" style={{ position: 'inherit' }}>
          <div className="container-fluid">
            <Image alt="Актион-пресс" src="https://images.action-press.ru/action-press/logo/Logo-Action.png" width={179} height={25} />


            <div className="col-md-4 text-end delivery-info">
              {(currentUrl === '/order/finish/[orderingId]' || currentUrl === '/order/get/[orderingId]') ? (
                <p className='page-title bb'>Ваш заказ сформирован</p>
              ) : (
                <>
                  <span className="blue desktop"><strong>Москва,</strong> бесплатная доставка</span>
                  <span className="grey">8 (800) 505-29-85</span>
                </>
              )}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};