import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { OrderingForSale } from '../../interfaces/ordering/sale/orderingForSale';
import { OrderingForSaleService } from '../../services/orderingForSale.service';
import { InstallmentTinkoff } from '../../interfaces/tinkoff/installmentTinkoff';

export type OrderingForSaleState = {
    orderingForSale?: OrderingForSale | null;
    installmentTinkoffList?: InstallmentTinkoff[] | null;
    error: boolean;
};

export type GetDataOrderingForSalePaymentType = {
    orderingId: string;
    url: string;
    urlInstallmentTinkoffList: string;
};

const initialState: OrderingForSaleState = {
    orderingForSale: null,
    installmentTinkoffList: null,
    error: false
};

export const getDataOrderingForSalePayment = createAsyncThunk('ordering/orderingForSale', async (param: GetDataOrderingForSalePaymentType) => {
    const resultOrderingForSale = await OrderingForSaleService.getDataOrderingForSalePayment(param.orderingId, param.url);

    const resultInstallmentTinkoffList = await OrderingForSaleService.getInstallmentTinkoffList(param.urlInstallmentTinkoffList);

    return {resultOrderingForSale, resultInstallmentTinkoffList};
});


export const orderingForSaleSlice = createSlice({
    name: 'orderingForSale',
    initialState,
    reducers: {
    },
    extraReducers: 
        (builder) => {
        builder.addCase(getDataOrderingForSalePayment.fulfilled, (state, { payload }) => {
            state.orderingForSale = payload.resultOrderingForSale;
            state.installmentTinkoffList = payload.resultInstallmentTinkoffList;
        }).addCase(getDataOrderingForSalePayment.rejected, (state) => {
            state.error = true;
        }).addCase(getDataOrderingForSalePayment.pending, (state) => {
            state.error = false;
        });
    }
});

export default orderingForSaleSlice.reducer;
