import { useRouter } from 'next/router';
import Image from 'next/image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export const HeaderMobile = (): JSX.Element => {
  const router = useRouter();

  const currentUrl = router.pathname;
  
  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-md fixed-top d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom" style={{ position: 'inherit' }}>
          <div className="container-fluid">
            <Row>
              <Col xs={6} sm={6} md={6}>
                <Image alt="Актион-пресс" src="https://images.action-press.ru/action-press/logo/Logo-Action.png" width={179} height={25} />
              </Col>
              
              <Col xs={6} sm={6} md={6}>
                {(currentUrl === '/order/finish/[orderingId]' || currentUrl === '/order/get/[orderingId]') ? (
                  <p className='page-title bb'>Ваш заказ сформирован</p>
                ) :
                  (
                    <>
                      <span className="page-title blue"><strong>Москва,</strong></span>
                      <br/>
                      <span className="page-title grey">8 (800) 505-29-85</span>
                    </>
                ) }
                

              </Col>


            </Row>

          </div>
        </nav>
      </div>
    </header>
  );
};