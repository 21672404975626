export const Footer = (): JSX.Element => {

  return (
    <footer className="container fixed-bottom border-top mt-auto py-3" style={{backgroundColor: 'white'}}>
        <span style={{fontSize: '13px'}}>Мы с уважением относимся к защите конфиденциальности своих клиентов.
        &nbsp;<a href="/doc/polsogaction.html" target="_blank">Пользовательское соглашение</a><br />
        &copy; {new Date().getFullYear()} &laquo;Актион-МЦФЭР&raquo;
      </span>
    </footer>
  );
};
