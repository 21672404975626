import getResource from "../infrastructure/getResource";
import { MethodRequest } from "../interfaces/fetch/methodRequest";
import { IParametersFetch } from "../interfaces/fetch/parametersFetch";
import { ResponseResult } from "../interfaces/fetch/responseResult";
import { TypeReturn } from "../interfaces/fetch/typeReturn";
import { CreateOrderingOnSaleOrderCrmParam } from "../interfaces/ordering/crm/createOrderingOnSaleOrderCrmParam";
import { CreateOrderingOnSaleOrderCrmResult } from "../interfaces/ordering/crm/result/createOrderingOnSaleOrderCrmResult";

/**
 * Создания заказа в eShop на основе заказа CRM
 * @param parameters - параметры
 * @returns 
 */
const createOrderingOnSaleOrderCrm = async (parameters: CreateOrderingOnSaleOrderCrmParam, url: string) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const parametersFetch: IParametersFetch<string> = {
    url: url,//config.ServiceOrdering.CreateOrderingOnSaleOrderCrm,//'http://eshop-4219-ordering-eshop.dev.eshop.aservices.tech/api/v1/Ordering/CreateOrderingOnSaleOrderCrm',
    methodRequest: MethodRequest.POST,
    body: JSON.stringify(parameters),
    typeReturn: TypeReturn.json,
    headers: headers,
  };

  const result: ResponseResult = await getResource(parametersFetch);
  return result.result as CreateOrderingOnSaleOrderCrmResult;
};

export const CrmService = {
  createOrderingOnSaleOrderCrm
};