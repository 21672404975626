import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderingInfoService } from '../../services/orderingInfo.service';

export type GetInfoOrderingType = {
  orderingId: string;
  url: string;
};

export const getInfoOrdering = createAsyncThunk('ordering/infoOrdering', async (param: GetInfoOrderingType) => {
  const result = OrderingInfoService.getOrderingInfo(param.orderingId, param.url);

  return result;
});