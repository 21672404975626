import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CreateOrderingOnSaleOrderCrmParam } from '../../interfaces/ordering/crm/createOrderingOnSaleOrderCrmParam';
import { CreateOrderingOnSaleOrderCrmResult } from '../../interfaces/ordering/crm/result/createOrderingOnSaleOrderCrmResult';
import { CrmService } from '../../services/crm.service';

export type CrmState = {
  createOrderingOnSaleOrderCrmResult: CreateOrderingOnSaleOrderCrmResult | null;
  error: boolean;
  message: string;
};

export type CreateOrderingOnSaleOrderCrmType = {
  parameters: CreateOrderingOnSaleOrderCrmParam;
  url: string;
};

const initialState: CrmState = {
  createOrderingOnSaleOrderCrmResult: null,
  error: false,
  message: ''
};

export const createOrderingOnSaleOrderCrm = createAsyncThunk('ordering/crm', async (param: CreateOrderingOnSaleOrderCrmType) => {
  const result = CrmService.createOrderingOnSaleOrderCrm(param.parameters, param.url);

  return result;
});

export const crmSlice = createSlice({
  name: 'crm',
  initialState,
  reducers: {},
  extraReducers: 
        (builder) => {
        builder.addCase(createOrderingOnSaleOrderCrm.fulfilled, (state, { payload }) => {
            state.createOrderingOnSaleOrderCrmResult = payload;
        }).addCase(createOrderingOnSaleOrderCrm.rejected, (state) => {
            state.error = true;
        }).addCase(createOrderingOnSaleOrderCrm.pending, (state) => {
            state.error = false;
        });
    }
});

export default crmSlice.reducer;