export const FooterMobile = (): JSX.Element => {

  return (
    <footer className="footer container fixed-bottom border-top mt-auto py-3" style={{backgroundColor: 'white'}}>
        Мы с уважением относимся к защите конфиденциальности своих клиентов.
        &nbsp;<a href="/doc/polsogaction.html" target="_blank">Пользовательское соглашение</a><br />
        &copy; {new Date().getFullYear()} &laquo;Актион-МЦФЭР&raquo;
    </footer>
  );
};
