//import config from "../configs/config";
import getResource from "../infrastructure/getResource";
import { MethodRequest } from "../interfaces/fetch/methodRequest";
import { IParametersFetch } from "../interfaces/fetch/parametersFetch";
import { ResponseResult } from "../interfaces/fetch/responseResult";
import { TypeReturn } from "../interfaces/fetch/typeReturn";
import { GetOrderingIdOnPreOrderingIdResult } from "../interfaces/ordering/getOrderingIdOnPreOrderingIdResult";
import { Ordering } from "../interfaces/ordering/pre/ordering";
import { OrderingForSale } from "../interfaces/ordering/sale/orderingForSale";
import { Partner } from "../interfaces/partner/partner";
import { CreateModel } from "../interfaces/tinkoff/createModel";
import { CreateParameters } from "../interfaces/tinkoff/createParameters";
import { InstallmentTinkoff } from "../interfaces/tinkoff/installmentTinkoff";

/**
 * Получить данные для sale оплаты заказа
 * @param orderingId - id заказа
 * @returns 
 */
const getDataOrderingForSalePayment = async (orderingId: string, url: string) => {
    const parameters: IParametersFetch<string> = {
        url: `${url}/?orderingId=${orderingId}`,//`${config.ServiceOrdering.GetDataOrderingForSalePayment}/?orderingId=${orderingId}`,
        methodRequest: MethodRequest.GET,
        body: null,
        typeReturn: TypeReturn.json,
    };
    
    const result: ResponseResult = await getResource(parameters);
    return result.result as OrderingForSale;
};

/**
 * Получение данных по заказу для Тинькоффа
 * @param parametersForTinkoff - Параметры
 * @returns 
 */
const getOrderingForTinkoff = async (parametersForTinkoff: CreateParameters, url: string) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const parameters: IParametersFetch<string> = {
        url: url,//config.ServiceInternetAcquiring.GetOrderingForTinkoff,
        methodRequest: MethodRequest.POST,
        body: JSON.stringify(parametersForTinkoff),
        typeReturn: TypeReturn.json,
        headers: headers,
    };

    const result: ResponseResult = await getResource(parameters);

    return result.result as CreateModel;
};

/**
 * Получение данных по пред-заказу
 * @param preOrderingId - id пред-заказа
 * @returns 
 */
const getPreOrdering = async (preOrderingId: string, url: string) => {
    const parameters: IParametersFetch<string> = {
        url: `${url}/?preOrderingId=${preOrderingId}`,//`${config.ServiceOrdering.GetPreOrdering}/?preOrderingId=${preOrderingId}`,
        methodRequest: MethodRequest.GET,
        body: null,
        typeReturn: TypeReturn.json,
    };

    const result: ResponseResult = await getResource(parameters);

    return result;
};

/**
 * Получить партнера по Id или номеру
 * @param partnersId - Id партнера
 * @param partnerNumber - Номер партнера
 * @returns 
 */
const getPartnerOnIdOrNumber = async (partnersId?: string | null, partnerNumber?: number | null, url?: string) => {
    const parameters:
        IParametersFetch<string> = {
        url:partnersId ? `${url}/?partnersId=${partnersId}` : `${url}/?partnerNumber=${partnerNumber}`,
            //partnersId ? `${config.ServiceApi.GetPartnerOnIdOrNumber}/?partnersId=${partnersId}` : `${config.ServiceApi.GetPartnerOnIdOrNumber}/?partnerNumber=${partnerNumber}`,
        methodRequest: MethodRequest.GET,
        body: null,
        typeReturn: TypeReturn.json,
    };
    
    const result: ResponseResult = await getResource(parameters);

    return result.result as Partner;
};

/**
 * Получить id заказа по Id предзаказа
 * @param preOrderingId - id предзаказа
 * @returns 
 */
const getOrderingIdOnPreOrderingId = async (preOrderingId: string, url: string) => {
    const parameters:
        IParametersFetch<string> = {
        url: `${url}/?preOrderingId=${preOrderingId}`,//`${config.ServiceOrdering.GetOrderingIdOnPreOrderingId}/?preOrderingId=${preOrderingId}`,
        methodRequest: MethodRequest.GET,
        body: null,
        typeReturn: TypeReturn.json,
    };
    
    const result: ResponseResult = await getResource(parameters);
    return result.result as GetOrderingIdOnPreOrderingIdResult;
};

/**
 * Получение url на переход в интернет-эквайринг для ранее созданной заявки
 * @param orderingId - id заказа
 * @param url - url на апи
 * @returns 
 */
const getPayUrlInternetAcquiringOnOrdering = async (orderingId: string, url: string) => {
    const parameters: IParametersFetch<string> = {
        url: `${url}/?orderingId=${orderingId}`,
        methodRequest: MethodRequest.GET,
        body: null,
        typeReturn: TypeReturn.string
    };

    const result: ResponseResult = await getResource(parameters);

    return result.result as string;
};

/**
 * Получить список промокодов Тинькоффа
 * @param url - url на апи
 * @returns 
 */
const getInstallmentTinkoffList = async (url: string) => {
    const parameters: IParametersFetch<string> = {
        url: `${url}`,
        methodRequest: MethodRequest.GET,
        body: null,
        typeReturn: TypeReturn.json
    };

    const result: ResponseResult = await getResource(parameters);

    return result.result as InstallmentTinkoff[];
};

/**
 * Получить р/с для Тинькофф рассрочку
 * @param url - url на апи
 * @param partnersId - Id партнера в eShop (Guid)
 * @param partnerId - Id партнера (int)
 * @returns 
 */
const getPaymentAccountForTinkoffInstallment = async (url: string, partnersId?: string | null, partnerId?: number | null) => {
    const parameters: IParametersFetch<string> = {
        url: `${url}/?partnersId=${partnersId ? partnersId : ''}&partnerId=${partnerId ? partnerId : ''}`,
        methodRequest: MethodRequest.GET,
        body: null,
        typeReturn: TypeReturn.string
    };

    const result: ResponseResult = await getResource(parameters);

    if (result.status === 404) return null;

    return result.result as string;
};

export const OrderingForSaleService = {
    getDataOrderingForSalePayment,
    getOrderingForTinkoff,
    getPreOrdering,
    getPartnerOnIdOrNumber,
    getOrderingIdOnPreOrderingId,
    getPayUrlInternetAcquiringOnOrdering,
    getInstallmentTinkoffList,
    getPaymentAccountForTinkoffInstallment
};