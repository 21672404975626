import getResource from "../infrastructure/getResource";
import { MethodRequest } from "../interfaces/fetch/methodRequest";
import { IParametersFetch } from "../interfaces/fetch/parametersFetch";
import { ResponseResult } from "../interfaces/fetch/responseResult";
import { TypeReturn } from "../interfaces/fetch/typeReturn";
import { InfoOrdering } from "../interfaces/ordering/info/infoOrdering";

/**
* Получение основной информации о заказе
* @returns 
*/
const getOrderingInfo = async (orderingId: string, url: string) => {
  const parameters: IParametersFetch<string> = {
    url: `${url}/?orderingId=${orderingId}`,//`${config.ServiceOrdering.GetOrderingInfo}/?orderingId=${orderingId}`, 
    methodRequest: MethodRequest.GET,
    body: null,
    typeReturn: TypeReturn.json,
  };

  const result: ResponseResult = await getResource(parameters);
  return result.result as InfoOrdering;
};

export const OrderingInfoService = {
  getOrderingInfo
};