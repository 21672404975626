import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { wrapper } from '../app/store';
import { Header } from '../components/header/header';
import { Footer } from '../components/footer/footer';
import { Device } from '../infrastructure/Device';
import '../styles/globals.scss';
import { HeaderMobile } from '../components/header/headerMobile';
import { FooterMobile } from '../components/footer/footerMobile';
import TagManager from 'react-gtm-module';


function ApplicationSale({ Component, pageProps }: AppProps): JSX.Element {
    useEffect(() => {
      TagManager.initialize({ gtmId: 'GTM-KQLW6X' });
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Device desktop>
        <Header />
      </Device>
      <Device mobile>
        <HeaderMobile />
      </Device>
      <main>
        <div className='container'>
          <Component {...pageProps} />
        </div>
      </main>

      <Device desktop>
        <Footer />
      </Device>
      <Device mobile>
        <FooterMobile />
      </Device>
    </>
  );
}

export default wrapper.withRedux(ApplicationSale);