import { createReducer } from '@reduxjs/toolkit';
import { InfoOrdering } from '../../interfaces/ordering/info/infoOrdering';
import { getInfoOrdering } from './actions';

export type InfoOrderingState = {
  infoOrdering?: InfoOrdering | null;
  error: boolean;
};

const initialState: InfoOrderingState = {
  infoOrdering: null,
  error: false
};

export const infoOrderingReducer = createReducer(initialState, (builder) => {
  builder.addCase(getInfoOrdering.fulfilled, (state, { payload }) => {
    state.infoOrdering = payload;
  }).addCase(getInfoOrdering.rejected, (state) => {
    state.error = true;
  }).addCase(getInfoOrdering.pending, (state) => {
    state.error = false;
  });
});


export default infoOrderingReducer;